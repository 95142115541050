class SmoothScroll{
	constrctor() {
		this.canEdit = false;
	}
	checkCanEdit(){
		if("CCM_EDIT_MODE" in window){
			this.canEdit = CCM_EDIT_MODE;
		}
		return this.canEdit;
	}
	exec(){
		this.checkCanEdit();
		let currentUrl,target,position,speed = 600,href,hrefPageUrl;

		if(!this.canEdit){
			$('a[href*="#"]').not(".nosms").click(function() {
				currentUrl = location.href;
				currentUrl = currentUrl.split("#")[0];
				href = $(this).prop("href");
				hrefPageUrl = href.split("#")[0];

				let _this = $(this);
				//#より前の絶対パスが、リンク先と現在のページで同じだったらスムーススクロールを実行
				if(hrefPageUrl == currentUrl){
					window._isSmoothScrolling = true;
					$('body').attr( { style: '' } );

					_this.addClass('active');
					//リンク先の#からあとの値を取得
					href = href.split("#");
					href = href.pop();
					href = "#" + href;

					//スムースクロールの実装
					target = $(href == "#" || href == "" ? 'html' : href);
					if(target.length > 0){
						position = target.offset().top; //targetの位置を取得
						$('body,html').animate({scrollTop: position}, speed, 'swing', function() {
							_this.removeClass('active');
							if($('.spnavi').css('display') == 'block'){
								$('.btn_sp_navi').click();
							}
						});
					}

					return false;
				}

			});
		}
		$(window).on('load',function(){
			currentUrl = location.href;
			target = $('#'+currentUrl.split("#")[1]);
			if(target.length > 0){
				setTimeout(function(){
					position = target.offset().top;
					$('body,html').animate({scrollTop: position}, speed, 'swing');
				},100);
			}
		});
	}
}
module.exports = SmoothScroll;
