export default class ajaxRequest{
	constructor(url,method){
		this.url = url;
		this.method = method;
		this.defer = $.Deferred();
	}

	sendRequest(){
		$.ajax({
			type:this.method,
			url: this.url,
			success: this.defer.resolve,
			error: this.defer.reject
		});
		return this.defer.promise();
	}
}
