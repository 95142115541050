const RequestPhoto = {
	send:function(obj){
		var defer = $.Deferred();
		$.ajax({
			type: 'GET',
			url: ajaxurl,
			dataType: 'json',
			context:obj,
			success: defer.resolve,
			error: defer.reject,
			data : {
				'action' : 'get_instagram_img',
				'_nonce': get_instagram_img_ajaxnounse
			}
		});
		return defer.promise();
	}
};

const template = require('./_photo.html');

RequestPhoto.send($(this)).then(
  function(response){
    let compiled = _.template(template);
    let compiled_data = _.map(response.media.data, function(d) {
		return compiled(d);
	});
	$('#photo-holder').append(compiled_data);
	$('.sns-block').removeClass('loading');
  },
  function(error){
    console.log(error);
  }
);
