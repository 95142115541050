import Responsive from './Responsive';
const r = new Responsive;

$(window).on("load scroll", function(){
	if(!r.isPC()){
		let header_sp = $('.header-sp');
		let header_sp_pos = header_sp.height();

		//スクロールするたびに実行
		$(this).on('load scroll',function () {
			let winTop = $(this).scrollTop();
			//スクロール位置がheader_spの位置より下だったらクラスfixedを追加
			if (winTop >= header_sp_pos) {
				header_sp.addClass('fixed');
				$('body').css({
					'padding-top':header_sp.height()
				});
			} else if (winTop === 0) {
				header_sp.removeClass('fixed');
				$('body').css({
					'padding-top':0
				});
			}
		});
	}
});
