import Request from './ajaxRequest';
import templateRenderer from './templateRenderer';
import Responsive from './Responsive';
import remodal from 'remodal';

const r = new Responsive();
const loading = $('.loading-filter');

export default function() {
	if(!ajaxurl) return false;
	moment.locale('ja', {
		weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"],
		weekdaysShort: ["日","月","火","水","木","金","土"],
	});
	$(function() {
		const eventModal = $('[data-remodal-id="event-modal"]').remodal();
		$('#js-fullcalender').fullCalendar({
			titleFormat:'M',
			defaultView: 'month',
			fixedWeekCount:false,
			timezone: 'Asia/Tokyo',
			contentHeight: '9999',
			events: `${ajaxurl}?_wpnonce=${nounse_events_per_month}&action=events_per_month`,
			eventRender: function (event, element, view) {
				let date_start = moment(event.start);
				let date_end;
				if(event.end){
					date_end = moment(event.end);
				}else{
					date_end = date_start
				}
				_.each(view.el.find('.fc-day.fc-widget-content'),function(item){
					let beginningTime = moment($(item).data('date'));
					let date_string = `<span class="fc-day-number">${beginningTime.format('D')}</span>`;

					if(beginningTime.isBetween(date_start,date_end, null, '[]')){
						$(item).addClass('hasEvent').html(date_string);
					}

					if($(item).data('date') == date_start.format('YYYY-MM-DD')){
						$(item).addClass('hasEvent').html(date_string);
					}
				});
			},
			eventAfterAllRender:function(view){
				let month_string = $('.fc-button-group h2').text();
				month_string = month_string.replace(/月/,'');
				let month_html = `<span class="fc-button-group-mon">${month_string}</span><span class="fc-button-group-mon-after">月</span>`;
				$('.fc-button-group h2').html(month_html);
				/**
				 * SP版はToday表示時に日付を挿入
				 */
				if(!r.isPC()){
					let cell_today = $('.fc-day.fc-today');
					let cell_today_date = moment(cell_today.data('date')).format('D');
					cell_today.html(`<span class="fc-day-number">${cell_today_date}</span>`);
				}
				/**
				 * SP用イベントカレンダーはクリックでモーダル起動、コンテンツはajaxリクエスト
				 */
				$('.fc-day.hasEvent').on('click',function(){
					let title = moment($(this).data('date')).format('YYYY年M月D日(ddd)');
					if(r.isPC()){
						return true;
					}
					loading.fadeIn(100);
					let requestUrl = `${ajaxurl}?_wpnonce=${nounse_events_per_day}&action=events_per_day&target=${$(this).data('date')}`;
					let ajaxRequest = new Request(requestUrl,'post');

					ajaxRequest.sendRequest()
					.then(function(data){
						let render = new templateRenderer();
						let html = `<div class="event-modal-list">${render.render('./templates/sp_events.html',data.data)}</div>`;
						let header = `<p class="event-modal-header">${title}</p>`;
						$('.js-sp-events_placeholder').html(`${header}${html}`);
					}).then(function(){
						loading.fadeOut(100);
						eventModal.open();
					});

					return false;
				});
				/**
				 * 各セル高さをコンテンツに合わせる
				 */
				if(r.isPC()){
					let allH = 0;
					$('.fc-row').each(function(){
						if(!$(this).hasClass('fc-widget-header')){
							let tableH = $(this).find('.fc-content-skeleton > table').height();
							if(tableH < 86){
								tableH = 86;
							}
							allH = allH + tableH;
							$(this).height(tableH);
						}
					});
					$('.fc-day-grid-container').height(allH);
					$('.fc-row.fc-week .fc-content-skeleton > table').css({'height':'100%'});
				}

			}
		});

		$("#js-fullcalender .fc-left h2").insertAfter('.fc-prev-button');
	});

}
