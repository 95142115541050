import "babel-polyfill";
import Responsive from "./Responsive";
import Accordion from "./Accordion";
import SmoothScroll from "./SmoothScroll";
import Tab from "./Tab";
import SpNavi from "./SpNavi";
import ToTop from "./ToTop";
import Gmap from "./Gmap";
import bowser from "bowser";
import matchheight from "jquery-match-height";
import sub_navi from "./sub_navi";
import swiper_top_sp from "./swiper_top_sp";
import mv from "./mv";
import PlaceholdSlider from "./PlaceholdSlider";
import ScrollFiexd from "./ScrollFiexd";
import swiper_shop from "./swiper_shop";
import remodalPrevNext from "./remodalPrevNext";
import form from "./form";
import swiper_shop_entry from "./swiper_shop_entry";

if (bowser.msie === true) {
	let version = "ie_" + Math.floor(bowser.version);
	$("body").addClass("ie " + version);
} else if (bowser.msedge === true) {
	$("body").addClass("edge");
} else if (bowser.firefox === true) {
	$("body").addClass("ff");
} else if (bowser.tablet === true) {
	$("body").addClass("tablet");
}
if (bowser.mobile === true) {
	$("body").addClass("device--mobile");
	$(".mv.mod-pc").remove();
	if (bowser.android === true) {
		$("body").addClass("device--mobile--android");
	}
} else {
	$(".mv.mod-sp").remove();
	$(".tel_link").on("click", function () {
		return false;
	});
}

let os,
	ua = navigator.userAgent;
if (ua.match(/Win(dows )?NT 10\.0/)) {
	os = "win10";
} else if (ua.match(/Win(dows )?NT 6\.3/)) {
	os = "win8_1";
} else if (ua.match(/Win(dows )?NT 6\.2/)) {
	os = "win8";
} else if (ua.match(/Win(dows )?NT 6\.1/)) {
	os = "win7";
} else {
	os = "";
}

$("body").addClass(os);
$(".current-menu-item")
	.find("a")
	.on("click", function () {
		return false;
	});
/* ===============================================
Check responsive state
=============================================== */
const r = new Responsive();
/* ===============================================
SP Navi
=============================================== */
let param = {
	target: "#spnavi",
	trigger: ".btn_sp_navi",
	filter: "resposive_flg",
	speed: 200,
	fixer: 100,
};
const spnavi = new SpNavi(param);
//SPナビでアコーディオンを開くたびにiScrollをリセットする
let spnav_accordion_1 = (scroller) => {
	$(".js-open-child").off();
	$(".js-open-child").on("click", function () {
		let target = $(this).next();
		if (target.css("display") == "none") {
			$(this).find(".btn-sp-navi-open-close").addClass("mod-active");
			target.slideDown(500);
		} else {
			$(this).find(".btn-sp-navi-open-close").removeClass("mod-active");
			target.slideUp(500);
		}

		setTimeout(function () {
			scroller.refresh();
		}, 550);
	});
};
spnavi.exec(spnav_accordion_1);

param = {
	target: "#spnavi-search",
	trigger: ".btn-sp-navi-search",
	filter: "resposive_flg",
	speed: 200,
	fixer: 100,
};
const spnavi_search = new SpNavi(param);
spnavi_search.exec();
/* ===============================================
box link
=============================================== */
// let box_link = new BlockLink();
// box_link.exec('.box_link');
/* ===============================================
Smooth Scroll
=============================================== */
const sms = new SmoothScroll();
sms.exec();
/* ===============================================
To Top
show/hide toggle
=============================================== */
const totop = new ToTop("#totop", 100, 400);
totop.exec();

const btmLink = new ToTop(".btmLink", 100, 400);
btmLink.exec();

/**
 * 外部リンク・PDF・Word・Exlsに_blankの自動付与
 *
 */
const link_icon = require("./link_icon");
link_icon();
/* ===============================================
Accordion

markp:
<dl>
	<dt></dt>
	<dd></dd>
	<dt></dt>
	<dd></dd>
	<dt></dt>
	<dd></dd>
</dl>
=============================================== */
const accordion = new Accordion($(".js-ac_trigger"));
accordion.exec();
/* ===============================================
Tab

markup:
<div class="tc">
	<ul>
		<li>tab1</li>
		<li>tab2</li>
		<li>tab3</li>
	</ul>
	<div class="tc_content">
		tabcon1tabcon1tabcon1tabcon1tabcon1tabcon1
	</div>
	<div class="tc_content">
		tabcon2tabcon2tabcon2tabcon2tabcon2tabcon2
	</div>
	<div class="tc_content">
		tabcon3tabcon3tabcon3tabcon3tabcon3tabcon3
	</div>
</div>
=============================================== */
const voice_tab = new Tab(
	".box-voice + .icon_wrap .box-voice-icon",
	".box-voice"
);
voice_tab.exec();

// /* gmap
// ----------------------- */
// let gmap = new Gmap();
// $(window).on('load',function(){
// 	gmap.exec();
// });
//

/*===============================================*/
//remodal
const rpn = new remodalPrevNext();
$(window).on("load", function () {
	rpn.run();
});
/**
 * todo
 * 以下ページごとの固有処理。各ページごと必要な分だけでまとめてファイル生成
 */
$(window).on("load", function () {
	$(".js-map").each(function () {
		let op = {
			target: $(this).attr("id"),
			center: {
				lat: $(this).data("lat"),
				lng: $(this).data("lng"),
			},
			pins: {
				lat: $(this).data("lat"),
				lng: $(this).data("lng"),
			},
			settings: {
				zoom: 17,
				title: "map",
			},
		};
		if ($(this).data("style") == false) {
			op.styles = false;
		} else {
			op.settings.icon = IMG_PATH + "map_pin.png";
		}

		if ($(this).data("zoom") > 0) {
			op.settings.zoom = $(this).data("zoom");
		}
		let gmap = new Gmap(op);
		gmap.exec();
	});
});
/**
 * fire PlaceholdSlider
 */
$(window).on("load", function () {
	const PHS = new PlaceholdSlider();
	PHS.init();

	$(".js-phs-ctrl-next").on("click", function () {
		PHS.goNext();
	});

	$(".js-phs-ctrl-prev").on("click", function () {
		PHS.goPrev();
	});

	$(document).on("click", ".js-phs-pager .js-phs-item", function () {
		PHS.goPage($(this));
	});
});
/**
 * simple slider
 */
$(".js-simpleslide-ctrl").each(function () {
	let _this = $(this),
		totalpage = $(" > figure", this).not(".empty").length - 1,
		current_page = 0,
		main = _this.prev(".js-simpleslide-main"),
		active = "active",
		sps_interval = null;
	/**
	 * pager
	 */
	$(" > *", this)
		.not(".empty")
		.on("click", function () {
			let index = $(this).index();

			_this.find(" > figure").not(".empty").removeClass(active);
			$(this).addClass(active);

			main.find(" > figure")
				.removeClass(active)
				.eq(index)
				.addClass(active);

			$(".contents-month_set-items-wrap-inner")
				.removeClass(active)
				.eq(index)
				.addClass(active);

			current_page = index;
		});
	/**
	 * next
	 */
	let next = _this
		.next(".js-simpleslide-prevnext")
		.find(".js-simpleslide-next");
	next.on("click", function () {
		let nextpage = current_page + 1;
		if (nextpage > totalpage) {
			nextpage = 0;
		}

		_this.find(" > figure").not(".empty").eq(nextpage).click();

		return false;
	});
	/**
	 * prev
	 */
	let prev = _this
		.next(".js-simpleslide-prevnext")
		.find(".js-simpleslide-prev");
	prev.on("click", function () {
		let prevpage = current_page - 1;
		if (prevpage < 0) {
			prevpage = totalpage;
		}
		_this.find(" > figure").not(".empty").eq(prevpage).click();

		return false;
	});

	sps_interval = setInterval(function () {
		next.click();
	}, 5000);

	$(".area-has-deviated_line-slide")
		.on("mouseenter", function () {
			if (sps_interval !== null) {
				clearInterval(sps_interval);
				sps_interval = null;
			}
		})
		.on("mouseleave", function () {
			if (sps_interval == null) {
				sps_interval = setInterval(function () {
					next.click();
				}, 5000);
			}
		});
});

/**
 * event calendar
 */
import event_calendar from "./event_calendar";
event_calendar();

/**
 * designed scrollber
 */
import PerfectScrollbar from "perfect-scrollbar";

$(".js-desined-scrollbar").each(function () {
	let ps = new PerfectScrollbar($(this)[0], {
		maxScrollbarLength: 40,
	});
});

/**
 * form
 */
form();

/**
 * form-shop select-radio
 */
$("form.line .row-has_radio").each(function () {
	let parent = $(this);
	/**
	 * exec by each line
	 */
	parent.find(".select-week-js").on("click", function () {
		let radio_designed = parent.find(".select-week-js");
		let delivery = parent.find(".delivery");
		let index = $(this).index();
		/**
		 * reset
		 */
		delivery.prop("checked", false);
		radio_designed.removeClass("mod-on");
		/**
		 * active clicked element
		 */
		delivery.eq(index).prop("checked", true);
		$(this).addClass("mod-on");
	});
});

//radio-button
$("label").each(function () {
	let parent = $(this);
	let index = $(this).index();

	parent.find(".dummy-radio-js").on("click", function () {
		let radio_designed = parent.find(".dummy-radio-js"); //dummy-radio-jsの初期状態
		let radio_input = parent.find(".radio-js"); //type="radio "の初期状態
		let checked_bool = radio_input.prop("checked");

		radio_input.prop("checked", false);
		$(".dummy-radio-js").removeClass("active");

		radio_input.eq(index).prop("checked", true);
		$(this).addClass("active");
	});
});

//input[type]
$("label").each(function () {
	let parent = $(this);

	parent.find(".dummy-check-js").on("click", function () {
		let check_designed = parent.find(".dummy-check-js"); //dummy-check-jsの初期状態
		let check_input = parent.find('input[type="checkbox"]'); //type="checkbox"の初期状態
		let checked_bool = check_input.prop("checked");

		if (!checked_bool) {
			check_designed.addClass("active");
		} else {
			check_designed.removeClass("active");
		}
	});
});

//モーダル開いたときのz-indexを調整する。上からモーダル→overlay→元コンテンツの順に表示したい
$(function () {
	$(".box-link.mod_no_arrow.mod-half.mod-put_cart").on("click", function () {
		$(".remodal").css("z-index", "999999");
		$(".remodal-overlay").css("z-index", "5000");
		$(".header-scl").css("z-index", "200");
	});
});

if ($("#datepicker").length > 0) {
	let picker = new Pikaday({
		field: document.getElementById("datepicker"),
		i18n: {
			previousMonth: "前の月",
			nextMonth: "次の月",
			months: [
				"1月",
				"2月",
				"3月",
				"4月",
				"5月",
				"6月",
				"7月",
				"8月",
				"9月",
				"10月",
				"11月",
				"12月",
			],
			weekdays: [
				"日曜日",
				"月曜日",
				"火曜日",
				"水曜日",
				"木曜日",
				"金曜日",
				"土曜日",
			],
			weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
		},
		//カレンダーの年の後に表示する文字列
		yearSuffix: "年",
		//年の後に月を表示するか
		showMonthAfterYear: true,
	});
}

/**
 * 商品詳細ページの固定フッター
 */
if ($(".form.sp").length > 0) {
	let pos_form_sp = $(".form.sp").offset().top;
	let p_fixed_footer = $(".js-products-fixed-footer");
	$(window).on("scroll", function () {
		if (!r.isPC()) {
			let w_top = $(this).scrollTop();
			if (w_top > pos_form_sp) {
				p_fixed_footer.fadeIn(200);
			} else {
				p_fixed_footer.fadeOut(200);
			}
		} else {
			p_fixed_footer.fadeOut(1);
		}
	});
}

/**
 * stores masonry
 */
const container = document.querySelector("#js-masonry");
if (container) {
	imagesLoaded(container, function () {
		const msnry = new Masonry(container, {
			itemSelector: ".js-masonry-item",
			isFitWidth: true,
			columnWidth: 330,
		});
	});

	const $filter_items = $(".js-filter-masonry").find(".js-filter-item");
	$filter_items.each(function () {
		$(this).on("click", function () {
			const this_target = $(this).data("target");
			const target = [];

			if ($(this).hasClass("mod-active")) {
				if (this_target === "all") {
					return false;
				}
				$(this).removeClass("mod-active");
			} else {
				$(this).parents("ul").find("li").removeClass("mod-active");
				$(this).addClass("mod-active");
			}

			$(".js-filter-item.mod-active").each(function () {
				if ($(this).data("target") !== "all") {
					target.push("." + $(this).data("target"));
				}
			});

			const classes = target.join("");

			if (this_target !== "all" && classes) {
				$(".js-masonry-item").hide();
				if (this_target !== "eat" && this_target !== "buy") {
					$("._all").removeClass("mod-active");
				}

				$(classes).show();
			} else {
				if ($(this).parents("ul").find("._all").length > 0) {
					console.log($(this).parents("ul").find("._all"));
					$(this).parents("ul").find("._all").addClass("mod-active");
				}
				$(".js-masonry-item").show();
			}

			const msnry = new Masonry(container, {
				itemSelector: ".js-masonry-item",
				isFitWidth: true,
				columnWidth: 330,
			});
		});
	});
}

/**
 * recipe tags
 */
$(".js-tag_list_more").on("click", function (e) {
	e.preventDefault();
	const tagList = $(".tag_list");
	if (tagList.hasClass("is-show")) {
		tagList.removeClass("is-show");
		$(this).removeClass("is-show");
		$(this).find(".js-tag_list_text").text("もっと見る");
	} else {
		tagList.addClass("is-show");
		$(this).addClass("is-show");
		$(this).find(".js-tag_list_text").text("閉じる");
	}

	//   $(this).remove();
});
/**
 * 読み物リニューアル URLコピーボタン
 */
$(".js-clipboard").on("click", function () {
	copyUrl();
	$(this).addClass("is-copied");
	setTimeout(() => {
		$(this).removeClass("is-copied");
	}, 1500);
});

function copyUrl() {
	const element = document.createElement("input");
	element.value = location.href;
	document.body.appendChild(element);
	element.select();
	document.execCommand("copy");
	document.body.removeChild(element);
}
/**
 * 読み物リニューアル
 * 詳細のテキストリンクにclass付与
 */
$(".post-content.mod-yomimono-new a:not(:has(img))").addClass("has-border");
