import Swiper from 'swiper';
import Responsive from './Responsive';
const swiper_trigger = $('.swiper-container-shop');
const r = new Responsive();
const options = {
		effect: 'slide',
		direction: 'horizontal',
		pagination: '.swiper-pagination-shop',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		paginationClickable: true,
		loop: true,
		centeredSlides : true,
		slideToClickedSlide: true,
		spaceBetween: 10,
		slidesPerView:'auto',
		autoResize: false,
		resizeReInit: true
	};

if(swiper_trigger.length > 0){

	let swiper = undefined,
		timer = false,
		wrapper = $('.swiper-container-shop .swiper-wrapper'),
		slide = $('.swiper-container-shop .swiper-slide');

	$(window).on('load' , function() {
		if(r.isPC()){
			swiper = new Swiper(swiper_trigger, options);
		}
	});

	$(window).on('resize',function(){
		if(r.isPC()){
			if (timer !== false) {
				clearTimeout(timer);
			}

			timer = setTimeout(function() {
				if(swiper != undefined) {
					swiper.destroy(true,true);
					swiper = undefined;
					wrapper.removeAttr("style");
					slide.removeAttr("style");

					setTimeout(function(){
						swiper = new Swiper(swiper_trigger,options);
					},400);
				}else{
					swiper = new Swiper(swiper_trigger,options);
				}
			}, 200);
		}else{
			if(swiper != undefined) {
				swiper.destroy(true,true);
				swiper = undefined;
				wrapper.removeAttr("style");
				slide.removeAttr("style");
			}
		}
	});
}

