export default class templateRenderer{
	render(template,data){
		const ddd = require(`${template}`);
		let compiled = _.template(ddd);
		// テンプレートにバインド
		let html = '';
		_.each(data, function(d) {
			html += compiled(d);
		});
		return html;
	}
}
