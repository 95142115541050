export default () => {
	/**
	 * designed selectbox
	 */
	const setVal = (obj)=>{
		let v = obj.find('option:selected').val();
		if(obj.next('.js-dummy-input').length > 0){
			obj.next('.js-dummy-input').val(v);
		}else{
			obj.parent().find('.js-dummy-input').val(v);
		}
	}

	$(window).on('load',function(){
		setVal($('.js-dummy-select'));
	});

	$('.js-dummy-select').change(function(){
		setVal($(this));
	});
	/**
	 * auto set addr
	 */
	$('form').addClass('h-adr');
	/**
	 * confirm support
	 */
	$('.js-confirm').on('click',function(){
		$('input[name="submitConfirm"]').click();
		return false;
	});
	$('.js-submit').on('click',function(){
		$('input[name="submit"]').click();
		return false;
	});
	$('.js-back').on('click',function(){
		$('input[name="submitBack"]').click();
		return false;
	});
	/**
	 * inner link by selectbox
	 */
	$('.js-innerlink-form select').change(function(){
 		let href = `#${$(this).val()}`;
 		let target = $(href == "#" || href == "" ? 'html' : href);
		if(target.length > 0){
			let position = target.offset().top - 90;
			$('body,html').animate({scrollTop: position}, 200, 'swing');
		};
	});

	$('.js-radio-emphasis').change(function(){
		$('.js-radio-emphasis').parent('label').removeClass('active');
		if($(this).prop('checked')){
			$(this).parent('label').addClass('active');
		}
	});
}
