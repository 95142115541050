import Responsive from './Responsive';
const r = new Responsive;

$(function() {
	if(!$('body').hasClass('shop') && $('.bgmv').length > 0){
		let mv;
		let speed = 800;

		if(r.isPC()){
			mv = $('.mv.mod-pc');
		}else{
			mv = $('.mv.mod-sp');
		}

		$('body').animate({ scrollTop: 0 }, '1');
		$(window).on('scroll',function(){
			if($('#intro_filter').css('display') === 'none'){
				mv.fadeOut(speed);
				$('.home #wrap').animate({'opacity':1});
			}
		});

		let timer = setInterval(function(){
			if($('#intro_filter').css('display') === 'none'){
				setTimeout(function(){
					mv.fadeOut(speed);
					$('.home #wrap').animate({'opacity':1});
				},2000);

				clearInterval(timer);
			}
		},10);

	}else{
		$('.home #wrap').animate({'opacity':1});
	}
});

