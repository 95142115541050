
//subナビゲーションホバー
$('#js-open-child-sub').hover(function() {
	$(this).find('> .sub-menu').stop(true,true).fadeIn(400);
},
function(){
	$(this).find('> .sub-menu').stop(true,true).fadeOut(400);
});

//下層およびスクロールヘッダーホバー
$('.js-open-child-nav').hover(function() {
	$(this).find('> .sub-menu').stop(true,true).fadeIn(400);
},
function(){
	$(this).find('> .sub-menu').stop(true,true).fadeOut(400);
});

//下層およびスクロールヘッダー　fixed時左端固定、ページ下げると出現
$(window).on("scroll", function(){
	$("#fixed").css("left", -$(window).scrollLeft());

	var topdistance = 100;
	if($(window).scrollTop() > topdistance){
		$(".header-scl").fadeIn(500);
	}else{
		$(".header-scl").fadeOut(500);
	}
	//sp-headerのテキストを消す
	if($(window).scrollTop() > topdistance){
		$(".btn-shop p").fadeOut(500);
		$(".btn-sp-navi p").fadeOut(500);
	}else{
		$(".btn-shop p").fadeIn(500);
		$(".btn-sp-navi p").fadeIn(500);
	}
});
