
export default class PlaceholdSlider {
	constructor() {
		this.center = $('.js-phs-placeholder');
		this.right_part = 'js-phs-right_pager';
		this.left_part = 'js-phs-left_pager';
		this.pager_left = $('.'+this.left_part);
		this.pager_right = $('.'+this.right_part);
		this.slide_item = '.js-phs-item';
		this.globalmv_page = 1;
		this.active_flg = 'active';
		this.target_item = '';
		this.old_item = '';
		this.interval_after_set = 100;
		this.interval_replace_elem = 600;
		this.can_animate = true;
		this.interval = null;
	}
	/**
	 * load first Imae
	 */
	async load() {
		this.pager_right.find(this.slide_item).first().appendTo(this.center);
	}
	/**
	 * define sleep Utility
	 */
	async sleep(delay, result) {
		return new Promise(resolve => {
			setTimeout(() => resolve(result), delay);
		});
	}
	/**
	 * set NextItem to Placeholder
	 */
	async setNextItem(){
		this.old_item = this.center.find(this.slide_item);
		this.target_item = this.pager_right.find(this.slide_item).first();
		this.target_item.appendTo(this.center);
		this.pager_left.append(this.old_item.clone().removeClass('active'));
		return true;
	}
	/**
	 * set PrevItem to Placeholder
	 */
	async setPrevItem(){
		this.old_item = this.center.find(this.slide_item);
		this.target_item = this.pager_left.find(this.slide_item).last();
		this.target_item.appendTo(this.center);
		this.pager_right.prepend(this.old_item.clone().removeClass('active'));
		return true;
	}
	/**
	 * set Item related to pager to Placeholder
	 */
	async setPageItem(item){
		this.old_item = this.center.find(this.slide_item);
		this.target_item = item;
		this.target_item.appendTo(this.center);
		return true;
	}
	/**
	 * set class for animate and toggle
	 */
	async crossFade(){
		this.old_item.removeClass(this.active_flg);
		this.target_item.addClass(this.active_flg);
		this.link();
		return true;
	}
	/**
	 * go to next slide
	 */
	async goNext(){
		if(this.can_animate){
			this.can_animate = false;
			await this.setNextItem();
			await this.sleep(this.interval_after_set);
			await this.crossFade();
			await this.sleep(this.interval_replace_elem);
			this.old_item.remove();
			this.pager_left.find(this.slide_item).first().appendTo(this.pager_right);

			this.can_animate = true;
		}

	}
	/**
	 * go to prev slide
	 */
	async goPrev(){
		if(this.can_animate){
			this.can_animate = false;
			await this.setPrevItem();
			await this.sleep(this.interval_after_set);
			await this.crossFade();
			await this.sleep(this.interval_replace_elem);
			this.old_item.remove();
			this.pager_right.find(this.slide_item).last().prependTo(this.pager_left);

			this.can_animate = true;
		}
	}
	/**
	 * go to selected page slide
	 */
	async goPage(item){
		if(this.can_animate){
			this.can_animate = false;

			let target;
			let target_add_to_left = [];
			let target_add_to_right = [];
			let direction = '';

			if(item.parent().hasClass(this.right_part)){
				for(target = 0;target < item.index();++target){
					target_add_to_left.push(item.parent().find(this.slide_item).eq(target));
				}

				for(target = 0;target < item.index() + 1;++target){
					target_add_to_right.push(this.pager_left.find(this.slide_item).eq(target));
				}
				direction = 'right';
			}else if(item.parent().hasClass(this.left_part)){

				let total_index_left = this.pager_left.find(this.slide_item).length;
				let total_index_right = this.pager_right.find(this.slide_item).length;

				for(target = item.index() + 1;target < total_index_left;++target){
					target_add_to_right.push(item.parent().find(this.slide_item).eq(target));
				}

				for(target = item.index();target < total_index_right;++target){
					target_add_to_left.push(this.pager_right.find(this.slide_item).eq(target));
				}
				direction = 'left';
			}

			await this.setPageItem(item);
			await this.sleep(this.interval_after_set);
			await this.crossFade();
			await this.sleep(this.interval_replace_elem);

			if(direction === 'right'){
				this.pager_left.append(this.old_item);
				this.pager_left.append(target_add_to_left);
				this.pager_right.append(target_add_to_right);
			}else if(direction === 'left'){
				this.pager_right.prepend(this.old_item);
				this.pager_left.prepend(target_add_to_left);
				this.pager_right.prepend(target_add_to_right);
			}

			this.can_animate = true;
		}

	}
	link(){
		$('.js-phs-item.active').on('click',function(){
			let link = $(this).data('link');
			if(link){
				location.href = link;
			}
		})
	}
	autoPlay(time = 3000){
		this.interval = setInterval(function(){
			this.goNext();
		}.bind(this), time);
	}
	autoStop(){
		let _that = this;
		$('.mv_slide').on('mouseenter',function(){
			if(_that.interval !== null){
				clearInterval(_that.interval);
				_that.interval = null;
			}
		}).on('mouseleave',function(){
			if(_that.interval == null){
				_that.autoPlay(5000)
			}
		});
	}
	/**
	 * First,call this.
	 */
	init(){
		this.load().then(() => {
			this.center.find(this.slide_item).addClass(this.active_flg);
			this.link();
			this.autoPlay(5000);
			this.autoStop();
		});
	}
}
