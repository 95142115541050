export default class remodalPrevNext {
	constructor() {
		this._remodal_wrapper_class = '.remodal-wrapper'
		this._remodal_class = '.remodal';
		this.transition = 0;
		this.separation = '.sep';
	}
	run(){
		const _this = this;
		$('.remodal-wrapper .remodal-nav').find('button').each(function(){
			let $parent = $(this).parents(_this._remodal_wrapper_class);
			let index = $(_this._remodal_wrapper_class).index($parent);
			let ParentModal = $(this).parents(_this._remodal_class).remodal();

			if($(this).hasClass('prev')){
				let prev_index = index - 1;
				if(prev_index > -1){
					$(this).on('click',function(){
						_this._onClick(ParentModal , _this._getOpenObj(prev_index));
					});
					if($(this).text() == ''){
						$(this).text(_this._getOpenTarget(prev_index).find('.modal-item-info_title').text());
					}
				}else{
					_this._toggleAppearance($(this));
				}
			}

			if($(this).hasClass('next')){
				let next_index = index + 1;
				if($(_this._remodal_wrapper_class).eq(next_index).length > 0){
					$(this).on('click',function(){
						_this._onClick(ParentModal , _this._getOpenObj(next_index));
						ParentModal.close();
					});
					if($(this).text() == ''){
						$(this).text(_this._getOpenTarget(next_index).find('.modal-item-info_title').text());
					}
				}else{
					_this._toggleAppearance($(this));
				}
			}
		});
	};

	_onClick(closeObj,openObj){
		closeObj.close();
		setTimeout(function(){
			openObj.open();
		},this.transition);
	}

	_getOpenObj(index){
		let modal_id = $(this._remodal_wrapper_class).eq(index).find(this._remodal_class).data('remodal-id');
		return $(`[data-remodal-id=${modal_id}]`).remodal();
	}

	_getOpenTarget(index){
		let modal_id = $(this._remodal_wrapper_class).eq(index).find(this._remodal_class).data('remodal-id');
		return $(`[data-remodal-id=${modal_id}]`);
	}

	_toggleAppearance(obj){
		obj.css({'visibility':'hidden'})
		$(this.separation).css({'visibility':'hidden'});
	}
}
