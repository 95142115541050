import Swiper from 'swiper';

const targetWrapper = Array.from(document.querySelectorAll(".slide-entry"));
// console.log(targetWrapper);
if(targetWrapper.length){
    targetWrapper.forEach(function(elem){
        const target = {
            container : elem.querySelector('.js-swiper-shop-entry'),
            next : elem.querySelector('.swiper-button-next'),
            prev : elem.querySelector('.swiper-button-prev'),
        }
        init(target);
    })
}

function init(target){
    let param = {
        speed:1000,
        nextButton:target.next,
        prevButton:target.prev,
        slidesPerView:'auto',
    }
    let swiper = new Swiper(target.container,param);
}

