import Swiper from 'swiper';
$(window).load(function() {
	const swiper = new Swiper('.mv-slide-sp', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		pagination: '.pagenate-sp',
		paginationClickable: true,
		loop: true,
		autoplay: 3000,
		speed: 1200,
		centeredSlides: true,
		bulletClass: 'swiper-pagination-bullet'
	});
});
