import Responsive from './Responsive';
import InfiniteScroll from 'infinite-scroll';

const targets = [
  {
    wrap: '.box-event_info-wrap',
    path : '.ppage_next',
    append : '.box-event_info-wrap > .box-event_info',
    hideOnLast:'.ppage_next'
  },
  {
    wrap: '.archive.s_product .thumbnail-basic-item-wrap',
    path: '.archive.s_product .nextpostslink',
    append: '.archive.s_product .thumbnail-basic-item-wrap',
    hideOnLast:'.archive.s_product .wp-pagenavi'
  }
];

for (let index = 0; index < targets.length; index++) {
  const target = targets[index];
  $(target.hideOnLast).css({'opacity':0});

  if ($(target.wrap).length) {
    const infScroll = new InfiniteScroll(target.wrap, {
      path: target.path,
      append: target.append,
      scrollThreshold: 150,
      history: false
    });

    infScroll.on('last', function (event, response, path) {
      $(target.hideOnLast).hide();
    });
  }
}

// if ($('.box-event_info-wrap').length){
//   const infScroll = new InfiniteScroll('.box-event_info-wrap', {
//     path: '.ppage_next',
//     append: '.box-event_info-wrap > .box-event_info',
//     scrollThreshold: 30,
//     history: false
//   });

//   infScroll.on('last', function (event, response, path) {
//     $('.ppage_next').hide();
//   });

// }

// if ($('.archive.s_product .thumbnail-basic-item-wrap').length) {
//   const infScrollProducts = new InfiniteScroll('.archive.s_product .thumbnail-basic-item-wrap', {
//     path: '.archive.s_product .nextpostslink',
//     append: '.archive.s_product .thumbnail-basic-item-wrap',
//     scrollThreshold: 30,
//     history: false
//   });
  
//   infScrollProducts.on('last', function (event, response, path) {
//     $('.archive.s_product .wp-pagenavi').hide();
//   });
// }

